<template>

  <div class="w-100"
    :class="((isActive == item.restaurant_id && this.$auth.setting && this.$auth.setting.product_type && this.$auth.setting.product_type.id == 2 && this.$auth.setting.store_selection == 0 && $auth.setting.manual_store_selection_by_customer == 1) ? 'active ' : '') + ' flex-grow-1 rest-item-list' + webVendorLayout">

    <router-link class="card restaurant-card" v-if="webVendorLayout == 2 && components == '' && isBrowser"
      :to="{ name: 'restaurant', params: { restaurant_id: this.encodeBase64(item.restaurant_id) } }">
      <div v-if="!right" class="">
        <div :class="{ 'Schedule': (isSchedule == '1'), 'closed': (isSchedule != '1') }"
          v-if="item.restaurant_on_off == '0'" class="itemimg-thumb">
          <div class="itemimg-thumb">
          </div>
        </div>
        <div class="">
          <div class="resturant-image | position-relative">
            <img v-lazy="item.icon_image" class="card-img-top" alt="Food Zone">
            <div class=""
              :class="item.restaurant_type == '1' || item.restaurant_type == '2' ? 'resturant-image-tag' : ''">
              <div class="w-100 d-flex align-items-center justify-content-center">
                <div class="d-flex align-items-center gap-2" v-if="item.restaurant_type == '1'">
                  <img v-lazy="require('../assets/images/veg-icon.svg')" class="w-auto h-auto" alt="Food Zone">
                  <span>{{ $t("Veg") }}</span>
                </div>
                <div class="d-flex align-items-center gap-2" v-if="item.restaurant_type == '2'">
                  <img v-lazy="require('../assets/images/nonveg-icon.svg')" class="w-auto h-auto" alt="Food Zone">
                  <span style="color: rgba(255, 0, 0, 1);">{{ $t("Non Veg") }}</span>
                </div>
                <!-- <h5 class="mt-0 me-5 item-name">{{ item.item_name }}</h5> -->
              </div>
            </div>
          </div>
          <div class="card-body p-3">
            <div class="d-flex justify-content-between align-items-center mb-2">
              <h5>{{ item.name }}</h5>
              <span class="rating-badge" v-if="item.rating != 0"
                :class="(parseFloat(item.rating).toFixed(1) > 2.0 || parseFloat(item.rating).toFixed(1) == 0.0 ? 'green-cls' : 'red-cls')">
                <i class="fas fa-star me-2"></i>
                <span v-if="item.rating == 0">{{ parseFloat(item.rating).toFixed(1) }}</span>
                <span v-else>{{ parseFloat(item.rating).toFixed(1) }}</span>
              </span>
            </div>
            <div class="restaurant-info">

              <p class="item-name-nw">{{ item.cuisine_name.join(', ') }}</p>

              <div class="d-flex align-items-center" v-if="this.$route.params.banner_id">
                <img v-if="item.show_customer_app" src="../assets/images/Lightning.svg" alt="offer" class="mb-1"
                  style="width: 18px; height: 18px;">
                <p v-if="item.is_pre_order == '1'">{{ $t("Pre Order Only") }}
                </p>
                <p v-else-if="item.show_customer_app == 'distence'">{{ item.delivery_time }} {{
                  $auth.setting.distance_metric }}
                </p>
                <p v-else-if="item.show_customer_app == 'time'">{{ item.delivery_time }} {{ $t("Mins") }}
                </p>
                <div class="d-flex align-items-center">
                  <i class="bi bi-lightning-charge-fill time-icon"></i>
                  <p v-if="item.two_person_price > 0" class="mx-2 d-flex align-items-center price-info">
                    <span v-html="$auth.setting.currency" class="currency"></span>
                    {{ parseFloat(item.two_person_price).toFixed(1) }} for two
                  </p>
                </div>
              </div>
              <div class="d-flex align-items-center" v-else-if="this.$route.path === '/search'">
                <img v-if="item.show_customer_app" src="../assets/images/Lightning.svg" alt="offer" class="mb-1"
                  style="width: 18px; height: 18px;">
                <p v-if="item.is_pre_order == '1'">{{ $t("Pre Order Only") }}
                </p>
                <p v-else-if="item.show_customer_app == 'distence'">{{ item.delivery_time }} {{
                  $auth.setting.distance_metric }}
                </p>
                <p v-else-if="item.show_customer_app == 'time'">{{ item.delivery_time }} {{ $t("Mins") }}
                </p>
                <div class="d-flex align-items-center">
                  <i class="bi bi-lightning-charge-fill time-icon"></i>
                  <p v-if="item.two_person_price > 0" class="mx-2 d-flex align-items-center price-info">
                    <span v-html="$auth.setting.currency" class="currency"></span>
                    {{ parseFloat(item.two_person_price).toFixed(1) }} for two
                  </p>
                </div>
              </div>

              <div class="d-flex align-items-center" v-else>
                <i class="bi bi-lightning-charge-fill time-icon"></i>
                <img v-if="item.show_customer_app" src="../assets/images/Lightning.svg" alt="offer" class="mb-1"
                  style="width: 18px; height: 18px;">
                <p v-if="item.is_pre_order == '1'">{{ $t("Pre Order Only") }}
                </p>
                <p v-else-if="item.show_customer_app == 'distence'">{{ item.delivery_times }} {{
                  $auth.setting.distance_metric }}
                </p>
                <p v-else-if="item.show_customer_app == 'time'">{{ item.delivery_time }}
                </p>
                <div class="d-flex align-items-center">
                  <i class="bi bi-lightning-charge-fill time-icon"></i>
                  <p v-if="item.two_person_price > 0" class="mx-2 d-flex align-items-center price-info">
                    <span v-html="$auth.setting.currency" class="currency"></span>
                    {{ parseFloat(item.two_person_price).toFixed(1) }} for two
                  </p>
                </div>

              </div>
              <div class="divider border-secondary-subtle" v-if="item.discount_text || item.is_show_customer_app == 1">
              </div>
              <div class="offer-icon d-flex mt-1">
                <img v-if="item.discount_text" src="../assets/images/promocode.svg" alt="offer" class="offer-icon">
                <div class="offer-badge d-flex align-items-center">
                  <i class="bi bi-tag-fill offer-icon me-1"></i>
                  <p>{{ item.discount_text.length > 50 ? item.discount_text.substring(0, 50) + "..." :
                    item.discount_text }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </router-link>


    <router-link class="resturant-list-card d-block text-decoration-none text-dark"
      :to="{ name: 'restaurant', params: { restaurant_id: this.encodeBase64(item.restaurant_id) } }"
      v-if="webVendorLayout == 1 && components == '' && isBrowser">

      <div class="resturant-item d-flex align-items-start">
        <div class="restaurant-image rounded-lg overflow-hidden position-relative flex-shrink-0">
          <img v-lazy="item.icon_image" class="w-full h-32 object-cover" />
          <div :class="{ 'Schedule': (isSchedule == '1'), 'closed': (isSchedule != '1') }"
            v-if="item.restaurant_on_off == '0'" class="noclos"></div>
        </div>


        <div class="resturant-data flex-grow-1 m-0">
          <div class="d-flex align-items-center gap-2" v-if="item.restaurant_type == '1'">
            <img v-lazy="require('../assets/images/veg-icon.svg')" class="w-auto h-auto" alt="Food Zone">
            <span>{{ $t("Veg") }}</span>
          </div>
          <div class="d-flex align-items-center gap-2" v-if="item.restaurant_type == '2'">
            <img v-lazy="require('../assets/images/nonveg-icon.svg')" class="w-auto h-auto" alt="Food Zone">
            <span style="color: rgba(255, 0, 0, 1);">{{ $t("Non Veg") }}</span>
          </div>
          <h4 class="fw-semibold text-lg">{{ item.name }}</h4>
          <span class="resturant-food_name text-sm d-block">{{ item.cuisine_name.join(', ') }}</span>
          <div class="delivery-info text-sm text-gray-600">

            <div class="d-flex align-items-center flex-wrap" v-if="this.$route.params.banner_id">
              <img v-if="item.show_customer_app" src="../assets/images/Lightning.svg" alt="offer" class="mb-1"
                style="width: 18px; height: 18px;">
              <p v-if="item.is_pre_order == '1'">{{ $t("Pre Order Only") }}
              </p>
              <p v-else-if="item.show_customer_app == 'distence'">{{ item.delivery_time }} {{
                $auth.setting.distance_metric }}
              </p>
              <p v-else-if="item.show_customer_app == 'time'">{{ item.delivery_time }} {{ $t("Mins") }}
              </p>
              <div class="d-flex align-items-center">
                <i class="bi bi-lightning-charge-fill time-icon"></i>
                <p v-if="item.two_person_price > 0" class="mx-2 d-flex align-items-center price-info">
                  <span v-html="$auth.setting.currency" class="currency"></span>
                  {{ parseFloat(item.two_person_price).toFixed(1) }} for two
                </p>
              </div>
            </div>
            <div class="d-flex align-items-center flex-wrap" v-else-if="this.$route.path === '/search'">
              <img v-if="item.show_customer_app" src="../assets/images/Lightning.svg" alt="offer" class="mb-1"
                style="width: 18px; height: 18px;">
              <p v-if="item.is_pre_order == '1'">{{ $t("Pre Order Only") }}
              </p>
              <p v-else-if="item.show_customer_app == 'distence'">{{ item.delivery_time }} {{
                $auth.setting.distance_metric }}
              </p>
              <p v-else-if="item.show_customer_app == 'time'">{{ item.delivery_time }} {{ $t("Mins") }}
              </p>
              <div class="d-flex align-items-center">
                <i class="bi bi-lightning-charge-fill time-icon"></i>
                <p v-if="item.two_person_price > 0" class="mx-2 d-flex align-items-center price-info">
                  <span v-html="$auth.setting.currency" class="currency"></span>
                  {{ parseFloat(item.two_person_price).toFixed(1) }} for two
                </p>
              </div>
            </div>

            <div class="d-flex align-items-center flex-wrap" v-else>
              <img v-if="item.show_customer_app" src="../assets/images/Lightning.svg" alt="offer" class="mb-1"
                style="width: 18px; height: 18px;">
              <p v-if="item.is_pre_order == '1'">{{ $t("Pre Order Only") }}
              </p>
              <p v-else-if="item.show_customer_app == 'distence'">{{ item.delivery_times }} {{
                $auth.setting.distance_metric }}
              </p>
              <p v-else-if="item.show_customer_app == 'time'">{{ item.delivery_time }}
              </p>
              <div class="d-flex align-items-center">
                <i class="bi bi-lightning-charge-fill time-icon"></i>
                <p v-if="item.two_person_price > 0" class="mx-2 d-flex align-items-center price-info">
                  <span v-html="$auth.setting.currency" class="currency"></span>
                  {{ parseFloat(item.two_person_price).toFixed(1) }} for two
                </p>
              </div>
            </div>
          </div>
          <div class="star-rate text-sm flex items-center mb-2" v-if="item.rating != 0"
            :class="(parseFloat(item.rating).toFixed(1) > 2.0 || parseFloat(item.rating).toFixed(1) == 0.0 ? 'text-green-600' : 'text-red-600')">
            <i class="fas fa-star text-yellow-500 mr-1"></i>
            <span>{{ parseFloat(item.rating).toFixed(1) }}</span>
          </div>
          <div class="divider2 border-secondary-subtle" v-if="item.discount_text && item.is_show_customer_app == 1">
          </div>
          <div class="offer-icon d-flex mt-1">
            <img v-if="item.discount_text" src="../assets//images/promocode.svg" alt="offer" class="offer-icon">
            <div class="offer-badge d-flex align-items-center">
              <i class="bi bi-tag-fill offer-icon me-1"></i>
              <p>{{ item.discount_text.length > 50 ? item.discount_text.substring(0, 50) + "..." :
                item.discount_text }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </router-link>





  </div>
</template>

<script>
import router from '../router';

export default {
  props: ['item', 'right', 'isPickupDelivery', 'isSchedule', 'webVendorLayout', 'components', 'businessResturant'],
  data() {
    return {
      isActive: this.base64_decode(localStorage.getItem("restaurantId"))
    }
  },
  methods: {
    isClosedFun(slug, restId) {
      window.$('#navStoreModal').modal('toggle');
      localStorage.setItem("restaurantId", this.base64_encode(restId));
      this.$emit("closedModal");
      this.$root.$emit('a-store-set-event', restId)
    },
    encodeBase64(value) {
      // Ensure window.btoa is available
      return this.isBrowser ? window.btoa(value) : '';
    }
  },
  computed: {
    isBrowser() {
      return typeof window !== 'undefined';
    }
  },
};
</script>
<style>
.price-info::before {
  content: "•";
  margin-right: 5px;
  font-size: 1.2em;
}
</style>