import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuelidate from "vuelidate";
import Toasted from 'vue-toasted';
import VueCountryCode from "vue-country-code-select";
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import axios from 'axios';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import config from './config';
import jQuery from 'jquery';
import $ from 'jquery';
import VueClipboard from 'vue-clipboard2';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import '@/assets/css/all.css';
import '@/assets/css/style.css';
import '@/assets/css/responsive.css';
import AuthPlugin from './plugins/authPlugin';
import VueSkeletonLoader from 'skeleton-loader-vue';
import i18n from './i18n';
import CookieLaw from 'vue-cookie-law';
import VueGtag from 'vue-gtag';
import VueLazyload from 'vue-lazyload';





window.axios = axios;
require('dotenv').config()
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Vuelidate);
Vue.use(VueCountryCode);
Vue.use(VueLazyload, {
    loading: require('./assets/images/loading.gif'),
    error: require('./assets/images/default-img.jpg')
});


Vue.use(VueClipboard);
Vue.use(VueSweetalert2);
Vue.use('vue-skeleton-loader', VueSkeletonLoader);
Vue.use(CookieLaw);
window.jQuery = jQuery;
window.$ = $;

Vue.use(Toasted, {
    duration: 4000,
    position: 'top-right',
    action: {
        onClick: (e, toastObject) => { toastObject.goAway(0); }
    }
});

Vue.use(AuthPlugin);
Vue.config.productionTip = false

window.axios.defaults.baseURL = config.api_url;
window.axios.defaults.headers.common['Content-Type'] = 'application/json';


new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
    // Added mounted to use VueGtag
    mounted() {
        if (localStorage.getItem('vnid')) {
            let vendor = atob(localStorage.getItem('vnid'));
            if (vendor == 41201) {
                Vue.use(VueGtag, {
                    config: { id: 'G-G5CJQDDL3E' }
                });
            }
        }
    }
}).$mount('#app')