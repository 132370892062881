<template>
    <div class="">
        <nav-bar></nav-bar>
        <section class="box-flex">
            <div class="col-md-12 frm">
                <div class="">
                    <div class="center-card-box">
                        <!-- <a href="javascript:void(0)" @click="goBack" class="bckbtn"><i
                                class="fas fa-arrow-left"></i></a><span>{{ pageName }}</span> -->
                        <a href="javascript:void(0)" class="bckbtn"></a><span>{{ pageName }}</span>
                        <div class="col-md-12" v-if="!iframeurl">
                            <h1><skeleton-loader-vue type="string" width="24%" height="20px" style="top: 20px" /></h1>
                            <skeleton-loader-vue type="string" width="100%" height="100px" style="top: 60px" />
                        </div>
                        <div v-else class="d-flex align-items-start plr15">
                            <iframe class="embed-responsive-item" :src="iframeurl"
                                style="width: 100%; border: none;min-height: 100px;max-height: 750px;height: 100vh;"
                                allowfullscreen />
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        </section>

        <!-- <div class="col-md-12 frm">
            <div class="center-card">
                <div class="center-card-box">
                    <a href="javascript:void(0)" @click="goBack" class="bckbtn"><i
                            class="fas fa-arrow-left"></i></a><span>{{ pageName }}</span>
                    <div class="col-md-12" v-if="!iframeurl">
                        <h1><skeleton-loader-vue type="string" width="24%" height="20px" style="top: 20px" /></h1>
                        <skeleton-loader-vue type="string" width="100%" height="100px" style="top: 60px" />
                    </div>
                    <div v-else class="d-flex align-items-start plr15">
                        <iframe class="embed-responsive-item" :src="iframeurl"
                            style="width: 100%; border: none;min-height: 100px;max-height: 750px;height: 100vh;"
                            allowfullscreen />
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div> -->
    </div>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue';
import NavBar from '../components/NavBar.vue'
import Footer from '../components/Footer.vue';
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
    components: { NavBar, Footer, 'skeleton-loader-vue': VueSkeletonLoader },
    // props: ['iframeurl', 'pageName', 'slug'],
    data() {
        return {
            is_load_skeleton: true,
            pageName: '',
            iframeurl: '',
        }
    },
    mounted() {
        // Load from route param on initial mount
        if (this.$route.params.page) {
            this.settingsApi()
        }
    },
    methods: {
        ...mapActions("order", ["getCurrentOrdersWithratingsList", "deliveryZone"]),
        settingsApi() {
            let host = this.$auth && this.$auth.setting && this.$auth.setting.web_settings.website_url ? this.$auth.setting && this.$auth.setting.web_settings.website_url : window.location.hostname;
            // let host = window.location.hostname;
            this.deliveryZone({
                vendor_id: this.$auth.getVendorId(),
                is_langauge: this.$store.state.lang.locale,
                latitude: JSON.parse(localStorage.getItem('latitude')),
                longitude: JSON.parse(localStorage.getItem('longitude')),
                order_web: host
                // order_web: 'gujcomart.online'
            }).then((data) => {
                if (data.settings.static_pages && Array.isArray(data.settings.static_pages)) {
                    // Find the matching page by slug
                    let pageData = data.settings.static_pages.find(page => page.slug === this.$route.params.page);
                    if (pageData) {
                        this.pageName = pageData.page_name; // Store the page name)
                        this.iframeurl = pageData.url; // Store the URL for iframe
                    } else {
                        console.warn("Page not found for slug:", this.$route.params.slug);
                    }
                }
            })
        },
        goBack() {
            let allPages = true;
            this.$emit("displaypages", allPages);
        }
    }
};
</script>
<style scoped>
a.bckbtn {
    font-size: 24px;
    padding: 20px;
}

.frm span {
    color: var(--theme-font-primary);
}
</style>