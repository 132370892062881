

const Orders = () => import("../../views/Orders.vue");
const OrderDetails = () => import("../../views/OrderDetails.vue");
const ReviewCart = () => import("../../views/ReviewCart.vue");
const Payment = () => import("../../views/Payment.vue");
const OrderStatus = () => import("../../views/OrderStatus.vue");
const Rating = () => import("../../views/Rating.vue");
const TrackOrder = () => import("../../views/TrackOrder.vue");
const PaymentGateway = () => import("../../views/PaymentGatway.vue");
const Subscription = () => import("../../views/Subscription.vue");
const SubscriptionCart = () => import("../../views/SubscriptionCart.vue");
const ManageSubscription = () => import('../../views/ManageSubscription.vue');
const SubscriptionStatus = () => import('../../views/SubscriptionStatus.vue');


const order = [
  {
    path: "orders",
    name: "orders",
    component: Orders,
  },
  {
    path: "order-details/:order_id",
    name: "order-details",
    component: OrderDetails,
  },
  {
    path: "order-status/:order_id",
    name: "order-status",
    component: OrderStatus,
  },
  {
    path: "rating/:order_id",
    name: "rating",
    component: Rating,
  },
  {
    path: "review-cart",
    name: "review-cart",
    component: ReviewCart,
  },
  {
    path: "payment",
    name: "payment",
    component: Payment,
  },
  {
    path: "trackorder/:order_id",
    name: "trackorder",
    component: TrackOrder,
  },
  {
    path: "payment-online",
    name: "payment-online",
    component: PaymentGateway,
  },
  {
    path: "review-subscription",
    name: "review-subscription",
    component: Subscription,
  },
  {
    path: "subscription-cart",
    name: "subscription-cart",
    component: SubscriptionCart,
  },
  {
    path: "manage-subscription",
    name: "manage-subscription",
    component: ManageSubscription,
  },
  {
    path: 'subscription-status/:subscription_id',
    name: 'subscription-status',
    component: SubscriptionStatus
  }
];

export default order;
